import * as signalR from "@aspnet/signalr";

import * as Leaflet from "leaflet";
import "leaflet-rotatedmarker";

import * as Moment from "moment";

import "@microsoft/dotnet-js-interop";

import { GeoJsonObject } from "geojson";

import { Theme } from "./ThemeSwitcher";

interface DotNetObject {
    invokeMethod<T>(methodIdentifier: string, ...args: any[]): T;

    invokeMethodAsync<T>(methodIdentifier: string, ...args: any[]): Promise<T>;
}

namespace GN2_8100.Client.Shared.Components {
    // TODO use TypeLite or similar
    interface EnvironmentFeature {
        DirectionTo: number;
        Speed: number;
    }

    interface AisVessel {
        Course: number;
        Mmsi: number;
    }

    export class LeafletMap {
        MapElement: HTMLElement;
        DotNetInstance: DotNetObject;

        Map: Leaflet.Map;

        TileLayer: Leaflet.GridLayer;
        CurrentsLayer: Leaflet.GeoJSON;
        CurrentsBottomLayer: Leaflet.TileLayer.WMS;
        VesselsLayer: Leaflet.GeoJSON;
        WindLayer: Leaflet.GeoJSON;

        Timestamp: Moment.Moment;

        constructor(elem: HTMLElement, dotNetInstance: DotNetObject) {
            this.MapElement = elem;
            this.DotNetInstance = dotNetInstance;

            this.Map = Leaflet.map(elem, {
                zoomControl: false
            });

            this.setTimestamp(Moment(Date.now()).add(2, 'hours')); // FIXME UTC
        }

        public setTimestamp(timestamp: Moment.Moment) {
            this.Timestamp = timestamp;

            // for now, only use full hours
            this.Timestamp = this.Timestamp.minute(0);
            this.Timestamp = this.Timestamp.seconds(0);

            console.log(this.Timestamp);

            if (this.CurrentsLayer) {
                if (map.CurrentsLayer)
                    map.Map.removeLayer(map.CurrentsLayer);

                this.loadCurrentsLayerAsync(this.Timestamp);
            }
        }

        private attachLayerLoadEvents(layer: Leaflet.Layer, layerName: string) {
            layer.addEventListener("loading", async () => {
                await this.setLayerLoading(layerName);
            });
            layer.addEventListener("load", async () => {
                await this.setLayerLoaded(layerName);
            });
        }

        private async setLayerLoading(layerName: string) {
            await this.DotNetInstance.invokeMethodAsync('LayerLoading', layerName);
        }

        private async setLayerLoaded(layerName: string) {
            await this.DotNetInstance.invokeMethodAsync('LayerLoaded', layerName);
        }

        public loadTileLayer(theme: Theme) {
            var accessToken = 'pk.eyJ1Ijoia3VrbGF1IiwiYSI6ImNqcG42YWQwczBiZWM0M29haGpsdXYzeXIifQ._2xnG02iqyUrK-cgF1NarQ';

            const attribution = "GALILEOnautic and its partners | Mapbox | Bundesamt für Seeschifffahrt und Hydrographie";

            switch (theme) {
                case Theme.Light:
                    this.TileLayer = Leaflet.tileLayer("https://api.tiles.mapbox.com/v4/mapbox.outdoors/{z}/{x}/{y}.png?access_token=" + accessToken, {
                        attribution: attribution,
                        detectRetina: true,
                        maxZoom: 18
                    });
                    break;
                case Theme.Dark:
                    this.TileLayer = Leaflet.tileLayer("https://api.tiles.mapbox.com/v4/mapbox.dark/{z}/{x}/{y}.png?access_token=" + accessToken, {
                        attribution: attribution,
                        detectRetina: true,
                        maxZoom: 18
                    });
                    break;
            }

            this.attachLayerLoadEvents(this.TileLayer, "tile");

            this.TileLayer.addTo(this.Map);
        }

        public async loadCurrentsLayerAsync(timestamp: Moment.Moment) {
            const layerName = "currents";

            this.setLayerLoading(layerName);

            var geoJson = await this.DotNetInstance.invokeMethodAsync<GeoJsonObject>('LoadCurrentsLayerAsync', timestamp);

            var arrowIcon = Leaflet.icon({
                iconUrl: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAdVBMVEUAAAA0SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV4ZONdZAAAAJnRSTlMAAQIFCg0QExscHyAhIyUmJy84PklMUFVzhp2ivtre4OTm7/n7/TDKWjgAAACASURBVChTvclHAsIwEMXQT6+hg+ktRPc/IgvbiWNmjZZ6UlO3I7M978L6DqiW9rfE/1+JP5fmtyX9qbQ/VCv7Rwn/AcDrU8vG/3sfgHMRZKRL+PKgeQmA0y78CJqVABPp6H8NmpawlaT1oacUNHRjJTWQ9Q94ApwMWNzgOjAg6wuA8SLBGsoj1gAAAABJRU5ErkJggg==',
                iconSize: [24, 24],
                iconAnchor: [0, 0]
            });

            this.CurrentsLayer = Leaflet.geoJSON(geoJson, {
                pointToLayer: function (feature, latlng) {
                    return Leaflet.marker(latlng, {
                        icon: arrowIcon,
                        rotationAngle: (<EnvironmentFeature>feature.properties).DirectionTo,
                        title: (<EnvironmentFeature>feature.properties).Speed.toString()
                    });
                }
            });

            this.CurrentsLayer.addTo(this.Map);

            this.setLayerLoaded(layerName);
        }

        public async loadWindLayerAsync(timestamp: Moment.Moment) {
            const layerName = "wind";

            this.setLayerLoading(layerName);

            var geoJson = await this.DotNetInstance.invokeMethodAsync<GeoJsonObject>('LoadWindLayerAsync', timestamp);

            var arrowIcon = Leaflet.icon({
                iconUrl: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAdVBMVEUAAAA0SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV40SV4ZONdZAAAAJnRSTlMAAQIFCg0QExscHyAhIyUmJy84PklMUFVzhp2ivtre4OTm7/n7/TDKWjgAAACASURBVChTvclHAsIwEMXQT6+hg+ktRPc/IgvbiWNmjZZ6UlO3I7M978L6DqiW9rfE/1+JP5fmtyX9qbQ/VCv7Rwn/AcDrU8vG/3sfgHMRZKRL+PKgeQmA0y78CJqVABPp6H8NmpawlaT1oacUNHRjJTWQ9Q94ApwMWNzgOjAg6wuA8SLBGsoj1gAAAABJRU5ErkJggg==',
                iconSize: [24, 24],
                iconAnchor: [0, 0]
            });

            this.WindLayer = Leaflet.geoJSON(geoJson, {
                pointToLayer: function (feature, latlng) {
                    return Leaflet.marker(latlng, {
                        icon: arrowIcon,
                        rotationAngle: (<EnvironmentFeature>feature.properties).DirectionTo,
                        title: (<EnvironmentFeature>feature.properties).Speed.toString()
                    });
                }
            });

            this.WindLayer.addTo(this.Map);

            this.setLayerLoaded(layerName);
        }

        public loadCurrentsBottomLayer() {
            return;
            this.CurrentsBottomLayer = Leaflet.tileLayer.wms('https://www.geoseaportal.de/wss/service/PredictionModel_WaterCurrent_Bottom/guest?', {
                layers: 'UTC_2000_01',
                crs: Leaflet.CRS.EPSG4326,
                format: 'image/svg+xml',
                transparent: true
            });

            this.CurrentsBottomLayer.addTo(this.Map);
        }

        public async loadVesselsLayerAsync() {
            // use an empty dummy here. Gets filled later in LeafletMap_SetVesselsLayerGeoJSON
            var emptyFeatureCollection: GeoJSON.FeatureCollection = { "type": "FeatureCollection", "features": [] };

            var vesselIcon = Leaflet.divIcon({
                html: '<i class="fas fa-ship"></i>',
                className: 'leaflet-fontawesomeicon'
            });

            this.VesselsLayer = Leaflet.geoJSON(emptyFeatureCollection, {
                pointToLayer: function (feature, latlng) {
                    return Leaflet.marker(latlng, {
                        icon: vesselIcon,
                        rotationAngle: (<AisVessel>feature.properties).Course, // FIXME sollte heading sein
                        title: (<AisVessel>feature.properties).Mmsi.toString()
                    })
                }
                //style: feature => {
                //    { }
                //}
            });

            this.VesselsLayer.addTo(this.Map);
        }

        public init() {
            // TODO: these kinds of coordinates shouldn't be hardcoded
            this.Map.setView([53.54, 8.55], 13);

            return true;
        }

        public zoomIn(e: MouseEvent) {
            if (this.Map.getZoom() < this.Map.getMaxZoom()) {
                this.Map.zoomIn(this.Map.options.zoomDelta * (e.shiftKey ? 3 : 1));
            }
        }

        public zoomOut(e: MouseEvent) {
            if (this.Map.getZoom() > this.Map.getMinZoom()) {
                this.Map.zoomOut(this.Map.options.zoomDelta * (e.shiftKey ? 3 : 1));
            }
        }
    }
}

declare global {
    // explicitly add componentFunctions to the existing Window interface
    interface Window { componentFunctions: any; pilotplugconstructor: any }
}

var map: GN2_8100.Client.Shared.Components.LeafletMap;

window.componentFunctions["initLeafletMap"] =
    (elem: HTMLElement, instance: any) => {
        if (map == null) {
            map = new GN2_8100.Client.Shared.Components.LeafletMap(elem, instance);

            map.init();
        }

        return true;
    };

window.componentFunctions["LeafletMap_Dispose"] =
    () => {
        if (map && map.Map) {
            map.Map.off();
            map.Map.remove();
        }
    }

// FIXME: fehlt noch initial. vielleicht einfach stattdessen aus LocalStorage auslesen
window.componentFunctions["LeafletMap_SetTileLayerTheme"] =
    (newTheme: Theme) => {
        if (map && map.TileLayer) {
            map.Map.removeLayer(map.TileLayer);
            map.loadTileLayer(newTheme);
        }

        return true;
    }

window.componentFunctions["LeafletMap_ToggleLayer"] =
    (layerName: string, isVisible: boolean) => {
        switch (layerName) {
            case "tile":
                if (map.TileLayer)
                    map.Map.removeLayer(map.TileLayer);
                break;
            case "currents":
                if (map.CurrentsLayer)
                    map.Map.removeLayer(map.CurrentsLayer);
                break;
            case "currentsBottom":
                if (map.CurrentsBottomLayer)
                    map.Map.removeLayer(map.CurrentsBottomLayer);
                break;
            case "vessels":
                if (map.VesselsLayer)
                    map.Map.removeLayer(map.VesselsLayer);
                break;
            case "wind":
                if (map.WindLayer)
                    map.Map.removeLayer(map.WindLayer);
                break;
        }

        if (isVisible) {
            switch (layerName) {
                case "tile":
                    map.loadTileLayer(Theme.Light); // FIXME load real value
                    break;
                case "currents":
                    map.loadCurrentsLayerAsync(map.Timestamp);
                    break;
                case "currentsBottom":
                    map.loadCurrentsBottomLayer();

                    if (map.CurrentsBottomLayer != null) {
                        (map.CurrentsBottomLayer as Leaflet.TileLayer.WMS).wmsParams.layers = 'UTC_1900_01';
                        map.CurrentsBottomLayer.redraw();
                    }
                    break;
                case "vessels":
                    map.loadVesselsLayerAsync();
                    break;
                case "wind":
                    map.loadWindLayerAsync(map.Timestamp);
                    break;
                default:
                    break;
            }   
        }

        return true; // returning void is still broken in Blazor JS interop
    };

window.componentFunctions["LeafletMap_SetTimestamp"] =
    (timestamp: string) => {
        console.log(timestamp);

        if (map)
            map.setTimestamp(Moment(timestamp));

        return true;
    }

window.componentFunctions["LeafletMap_ZoomIn"] =
    (e: MouseEvent) => map.zoomIn(e);

window.componentFunctions["LeafletMap_ZoomOut"] =
    (e: MouseEvent) => map.zoomOut(e);

window.componentFunctions["LeafletMap_SetVesselsLayerGeoJSON"] =
    (geojson: string) => {
        // TODO clear existing vessels
        // map.VesselsLayer.clearLayers() works but isn't very clean?
        map.VesselsLayer.clearLayers();
        map.VesselsLayer.addData(JSON.parse(geojson));
    }

//var connection = new signalR.HubConnectionBuilder()
//    .withUrl("/GN2_8100/hubs/vesselsonmap", {}) // FIXME relative URL
//    .build() as signalR.HubConnection;

//(connection.start() as Promise<void>).catch(err => console.log(err)).then(_ => {
//    connection.send("requestUpdate").catch(err => console.log(err));
//});

//connection.on("updateMarker", () => {
//    console.log("foo");

//    setTimeout(() => {
//        connection.send("requestUpdate").catch(err => console.log(err));
//    }, 500);
//});
